import React, { useRef, useState } from "react";
import { BsFillPinMapFill } from 'react-icons/bs';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";
import Fade from 'react-reveal/Fade';
import './ContactForm.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState("Submit");

    const sendEmail = (e) => {
        e.preventDefault();

        // Change the button text to "Message Sent"
        setButtonText("Message Sent");

        //    Service Id                     Template Id                 Public Key (Account Tab)
        emailjs.sendForm("service_bdfl0ie", "template_bsejaeg", form.current, 'CLcHWAKSemVMd1_sU')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset();

        // Reset button text after 4 seconds
        setTimeout(() => {
            setButtonText("Submit");
        }, 4000);
    };

    return (
        <>
            <div className="bg">
                <div className="contact2-header contact2-container">
                    <h1>DO YOU NEED URGENT HELP?</h1>
                    <p>Our Addiction and Recovery Support team is available to assist you</p>
                </div>

                <section className="contact-container container">
                    <div className="get_form_inner">
                        <div className="get_form_inner_text">
                            <h3>Get In Touch</h3>
                            <p><i>We'll keep your contact information strictly confidential.</i></p><br />
                        </div>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="inputs">
                                <input type="text" name='name' placeholder='Your Name' required pattern="[A-Za-z\s]+" title="Name should only contain letters." /> <br />
                                <input type="email" name='email' placeholder='Your Email' required title="Please enter a valid email address." /> <br />
                                <input type="tel" name='phone' placeholder='Your Phone' required pattern="\+?\d{1,4}[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}" title="Phone number should be in valid format, e.g., +1 (123) 456-7890" /> <br />
                                <input type="text" name='subject' placeholder='Subject' required /> <br />
                            </div>
                            <div>
                                <textarea name="message" placeholder="How can we help?" cols="30" rows="8" required></textarea>
                                <div className="disclaimer">
                                    <p>Santa Monica Rehab needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our  
                                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                                            <Link to="/privacy-policy"><span> Privacy Policy </span></Link>
                                        </LinkRoll></p>
                                </div>
                                <input type="submit" value={buttonText} />
                            </div>
                        </form>
                    </div>

                    <Fade left>
                        <div className="contact-cards-container">
                            <div className="get_form_inner2">
                                <h2>CALL US</h2>
                                <p><AiOutlinePhone /><a href="tel:2135844445"> +1 (213) 584-4445</a></p>
                            </div>

                            <div className="get_form_inner2">
                                <h2>EMAIL</h2>
                                <p><AiOutlineMail /> info@santamonicarehab.biz  </p><br />
                            </div>

                            <div className="get_form_inner2">
                                <h2>LOCATION</h2>
                                <p><BsFillPinMapFill /> Santa Monica, California</p>
                            </div>
                        </div>
                    </Fade>
                </section>
            </div>
        </>
    )
}

export default ContactForm;
